import { PRESS_COVERAGE } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'pressCoverage/';

/* Define Action Name */
const A_GET_PRESS_COVERAGES = 'getPressCoverages';
const A_GET_PRESS_COVERAGE = 'getPressCoverage';

/* Define Reset State Action Name */
const A_INITIAL_GET_PRESS_COVERAGES_STATE = 'initialGetPressCoveragesState';
const A_INITIAL_GET_PRESS_COVERAGE_STATE = 'initialGetPressCoverageState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_PRESS_COVERAGES = 'setGetPressCoverages';
const M_GET_PRESS_COVERAGE = 'setGetPressCoverage';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_PRESS_COVERAGES_STATE = 'setInitialGetPressCoveragesState';
const M_INITIAL_GET_PRESS_COVERAGE_STATE = 'setInitialGetPressCoverageState';

/* Define Export Name */
export const PRESS_COVERAGE_GET_PRESS_COVERAGES =
  MODULE_NAME + A_GET_PRESS_COVERAGES;
export const PRESS_COVERAGE_GET_PRESS_COVERAGE =
  MODULE_NAME + A_GET_PRESS_COVERAGE;

/* Define Reset State Name */
export const PRESS_COVERAGE_INITIAL_GET_PRESS_COVERAGES_STATE =
  MODULE_NAME + A_INITIAL_GET_PRESS_COVERAGES_STATE;
export const PRESS_COVERAGE_INITIAL_GET_PRESS_COVERAGE_STATE =
  MODULE_NAME + A_INITIAL_GET_PRESS_COVERAGE_STATE;

const state = {
  pressCoverages: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  pressCoverage: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_PRESS_COVERAGES]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PRESS_COVERAGE.getPressCoverages()
      .then((response) => {
        result = response;
        commit(M_GET_PRESS_COVERAGES, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PRESS_COVERAGES, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_PRESS_COVERAGE]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    // console.log(id);
    await PRESS_COVERAGE.getPressCoverage(id)
      .then((response) => {
        result = response;
        commit(M_GET_PRESS_COVERAGE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PRESS_COVERAGE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_PRESS_COVERAGES_STATE]({ commit }) {
    commit(M_INITIAL_GET_PRESS_COVERAGES_STATE);
  },
  [A_INITIAL_GET_PRESS_COVERAGE_STATE]({ commit }) {
    commit(M_INITIAL_GET_PRESS_COVERAGE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_PRESS_COVERAGES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.pressCoverages = {
        code: result.code,
        complete: true,
        data: d.map((x) => {
          return {
            id: x.id,
            logo: x.logo,
            content: x.short_content,
            sort: x.sort,
            title: x.title,
            dateTime: new Date(x.created_at).toLocaleDateString('en-US')
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.pressCoverages = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_PRESS_COVERAGE](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let x = result.data.button;

      state.pressCoverage = {
        code: result.code,
        complete: true,
        data: {
          button: {
            next: x.next,
            previous: x.previous,
            total: x.total,
            current: x.current
          },
          id: d.id,
          logo: d.logo,
          content: d.content,
          sort: d.sort,
          title: d.title,
          dateTime: new Date(d.created_at).toLocaleDateString('en-US')
        },
        message: null
      };
    } else {
      state.pressCoverage = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_GET_PRESS_COVERAGES_STATE](state) {
    state.pressCoverages = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_PRESS_COVERAGE_STATE](state) {
    state.pressCoverage = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
