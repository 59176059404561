<template>
  <v-footer padless>
    <v-divider class="greyDivider"></v-divider>
    <div class="wh-100 secondary">
      <div
        v-if="$vuetify.breakpoint.mobile"
        class="text-center mb-4 py-6 py-sm-12 content-wrapper"
      >
        <v-row no-gutters align="center" class="mb-4 mb-sm-8">
          <v-col cols="4" class="text-start">
            <span class="title--text text-uppercase font-weight-bold">{{
              $t('label.followUs')
            }}</span>
          </v-col>
          <v-col cols="8" class="d-flex flex-wrap">
            <template v-for="(i, index) in socialMedia">
              <a
                :key="index"
                v-if="platformInfo.data[i]"
                :href="platformInfo.data[i]"
                target="_blank"
                class="mr-2"
              >
                <img
                  :src="`/assets/image/${i}.png`"
                  class="social-media-icon"
                />
              </a>
            </template>
          </v-col>
        </v-row>
        <EmailSubscriptionField></EmailSubscriptionField>
      </div>

      <v-divider class="greyDivider"></v-divider>

      <v-row no-gutters class="py-0 py-md-10 content-wrapper">
        <template v-for="(item, colIndex) in fields">
          <v-col
            v-if="!$vuetify.breakpoint.mobile"
            :key="colIndex"
            cols="12"
            sm="6"
            md="3"
            class="mb-4 mb-md-0"
          >
            <template v-for="(i, index) in fields[colIndex]">
              <div :key="index" class="mb-4 text-caption">
                <div
                  v-if="index == 0"
                  class="title--text font-weight-bold text-uppercase"
                >
                  {{ $t(`label.${i.text}`) }}
                </div>
                <router-link
                  v-else
                  :to="{ name: i.routeName }"
                  class="text-decoration-none text--text"
                  :class="{ 'text-capitalize': i.text != 'termsOfUse' }"
                >
                  {{ $t(`label.${i.text}`) }}
                </router-link>
              </div>
            </template>
          </v-col>

          <v-col
            v-else
            :key="colIndex"
            cols="12"
            md="3"
            class="pt-6 pt-sm-8 pt-md-0 mb-6 mb-sm-10 mb-md-0 text-small text-sm-body-2"
          >
            <template v-for="(i, index) in fields[colIndex]">
              <div :key="index" class="mb-4">
                <div
                  v-if="index == 0"
                  class="title--text font-weight-bold text-uppercase"
                >
                  {{ $t(`label.${i.text}`) }}
                </div>
              </div>
            </template>
            <div class="d-flex justify-space-between">
              <template v-for="(i, index) in fields[colIndex]">
                <div :key="index" v-if="index != 0">
                  <router-link
                    :to="{ name: i.routeName }"
                    class="text-decoration-none text--text"
                    :class="{ 'text-capitalize': i.text != 'termsOfUse' }"
                  >
                    {{ $t(`label.${i.text}`) }}
                  </router-link>
                </div>
              </template>
            </div>
          </v-col>
        </template>

        <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" md="6">
          <EmailSubscriptionField class="mb-4"></EmailSubscriptionField>
          <div class="d-flex">
            <template v-for="(i, index) in socialMedia">
              <a
                :key="index"
                v-if="platformInfo.data[i]"
                :href="socialMediaLink(i, platformInfo.data[i])"
                target="_blank"
              >
                <img
                  :src="`/assets/image/${i}.png`"
                  class="mr-4 social-media-icon"
                />
              </a>
            </template>
          </div>
        </v-col>
      </v-row>

      <v-divider class="greyDivider"></v-divider>
      <v-card-text
        id="footerbottom"
        class="align-center text-small text-sm-caption text-center py-4 py-sm-6 py-md-8 label--text"
      >
        <div>
          {{ $t('message.footerCopyright', [copyrightYears]) }}
        </div>
        <div>
          <router-link :to="{ name: routeName.PRIVACY_POLICY }">
            {{ $t('label.privacyPolicy') }}
          </router-link>
        </div>
        <div>
          <router-link :to="{ name: routeName.TERMS_OF_USE }">
            {{ $t('label.termsOfUse') }}
          </router-link>
        </div>
      </v-card-text>
    </div>
  </v-footer>
</template>

<script>
  import EmailSubscriptionField from '@/views/components/EmailSubscriptionField';
  import { ROUTE_NAME } from '@/core/constants';
  import { formValidationHelper } from '@/core/utils';

  export default {
    name: 'Footer',
    components: {
      EmailSubscriptionField
    },
    data: () => ({
      routeName: ROUTE_NAME,
      formValidation: formValidationHelper,
      fields: [
        [
          {
            text: 'aboutUs'
          },
          {
            text: 'aboutCookXAsia',
            routeName: ROUTE_NAME.ABOUT_COOKX
          },
          {
            text: 'beCreatorPartner',
            routeName: ROUTE_NAME.ABOUT_SELLING
          },
          {
            text: 'contactUs',
            routeName: ROUTE_NAME.CONTACT_US
          }
          // {
          //   text: 'careers',
          //   routeName: ROUTE_NAME.CAREERS
          // }
        ],
        [
          {
            text: 'support'
          },
          {
            text: 'faqs',
            routeName: ROUTE_NAME.FAQ
          },
          {
            text: 'termsNConditions',
            routeName: ROUTE_NAME.TERMS_AND_CONDITIONS
          },
          {
            text: 'privacyPolicy',
            routeName: ROUTE_NAME.PRIVACY_POLICY
          }
        ]
      ],
      socialMedia: [
        'instagram',
        'facebook',
        'whatsapp',
        'youtube',
        'linkedin',
        'tiktok'
      ]
    }),
    computed: {
      copyrightYears() {
        const startedYear = 2021;
        let currentYear = new Date().getFullYear();
        let copyrightYears = startedYear;

        if (startedYear < currentYear) {
          copyrightYears = `${currentYear}`;
        }

        return copyrightYears;
      },
      platformInfo() {
        return this.$store.state.platformInfo.platformInfo;
      }
    },
    methods: {
      socialMediaLink(i, p) {
        let link = p;

        if (i == 'youtube') {
          link = 'https://www.youtube.com/channel/' + p;
        }

        return link;
      }
    }
  };
</script>

<style lang="scss">
  .v-footer {
    .footer__first-row {
      padding: 32px 96px;
    }
    .social-media-icon {
      height: 45px;
      width: 45px;
    }
  }

  @media (max-width: 1263px) {
    .v-footer {
      .footer__first-row {
        padding: 32px;
      }
    }
  }

  @media (max-width: 959px) {
    .v-footer {
      .social-media-icon {
        height: 40px;
        width: 40px;
      }
    }
  }
</style>
