import Vue from 'vue';
import App from './App.vue';
import router from '@/core/router';
import store from '@/core/store';

//Third part plugins
import i18n from './core/plugins/i18n';
import vuetify from './core/plugins/vuetify';
import { i18nService } from './core/services';
import './core/plugins/gtag';
import './core/plugins/vue-qr-code-component';
import './core/plugins/carousel';
import './core/plugins/v-viewer';

//Customize Style
import '@/assets/sass/init.scss';

//Reuseable UI Component
import AppButton from '@/views/components/base/AppButton';
import AppFormField from '@/views/components/base/AppFormField';
import AppRadio from '@/views/components/base/AppRadio';
Vue.component('AppButton', AppButton);
Vue.component('AppFormField', AppFormField);
Vue.component('AppRadio', AppRadio);
Vue.config.productionTip = false;

i18nService.init();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
