import {
  SHARED_OPEN_DIALOG_INFO,
  SHARED_CLOSE_DIALOG_INFO,
  SHARED_OPEN_WHATSAPP_DIALOG_INFO
} from '@/core/store/shared.module';
import { LOCAL } from '@/core/constants';
import { uiHelper } from '@/core/utils';

var common = {
  computed: {
    constantsData() {
      let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

      return constants
        ? JSON.parse(constants)
        : this.$store.state.constants.constants.data;
    }
  },
  methods: {
    openAppDialogInfo(type, title, description, buttons) {
      this.$store.dispatch(SHARED_OPEN_DIALOG_INFO, {
        type,
        title,
        description,
        buttons
      });
    },
    closeAppDialogInfo() {
      this.$store.dispatch(SHARED_CLOSE_DIALOG_INFO);
    },
    openWhatsAppDialog(buttons) {
      this.$store.dispatch(SHARED_OPEN_WHATSAPP_DIALOG_INFO, { buttons });
    }
  }
};

export default common;
