import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#E7DB47',
        secondary: '#334856',
        tertiary: '#E26F59',
        quaternary: '#78849E',
        senary: '#7D8184',
        septenary: '#9E9B35',
        octonary: '#BDCDE1',
        nonary: '#F6A000',
        denary: '#FDD08F',
        accent: '#639FCD',
        error: '#ef476f',
        text: '#d9d9d9',
        label: '#b6b9b8',
        title: '#808080',
        greyDivider: '#60707d',
        greyCard: '#e8e8e8',
        disabled: '#565360',
        background: '#ffffff',
        surface: '#ffffff',
        darkLayer: '#161515',
        lightBlue: '#eff6ff'
      }
    },
    options: { customProperties: true }
  },
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  }
});
