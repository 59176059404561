<template>
  <v-dialog
    v-model="dialogInfo.show"
    persistent
    width="450"
    content-class="app-dialog-info"
  >
    <v-card
      id="popupcard"
      class="d-flex justify-center align-center pa-10 pa-sm-5"
      v-if="dialogInfo.twobtn"
    >
      <div class="content">
        <img class="toplogo" src="/assets/image/e-logo.png" />
        <h2 class="text-center" style="margin-top: 80px">
          {{ $t('label.Report') }}
        </h2>
        <div class="requestover">{{ $t('label.requestover') }}</div>
        <div class="requestoverRemark">{{ $t('label.requestoverRemark') }}</div>
        <v-row class="two-btn d-flex justify-space-around">
          <template v-for="(items, index) in dialogInfo.buttons">
            <v-col :key="index" cols="6" md="6" class="text-center">
              <a @click="items.action">
                <img v-bind:src="items.text" />
              </a>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-card>
    <v-card
      min-height="400"
      class="d-flex justify-center align-center pa-10 pa-sm-5"
      v-if="!dialogInfo.twobtn"
    >
      <div class="content">
        <div class="text-center">
          <v-icon v-if="dialogInfo.type == 'info'" color="primary" class="icon"
            >mdi-information</v-icon
          >
          <v-icon v-if="dialogInfo.type == 'error'" color="error" class="icon"
            >mdi-alert-octagon</v-icon
          >
          <v-icon
            v-if="dialogInfo.type == 'warning'"
            color="warning"
            class="icon"
            >mdi-alert</v-icon
          >
          <v-icon
            v-if="dialogInfo.type == 'success'"
            color="success"
            class="icon"
            >mdi-check-bold</v-icon
          >
        </div>
        <div
          class="text-h7 text-center text-uppercase font-weight-bold mt-5 mb-2"
        >
          {{ dialogInfo.title }}
        </div>
        <div class="text-caption text-center mb-6">
          {{ dialogInfo.description }}
        </div>
        <v-row class="d-flex justify-space-around mt-4">
          <template v-for="(items, index) in dialogInfo.buttons">
            <v-col :key="index" cols="12" md="6" class="pa-1 pa-md-2">
              <v-btn
                :color="items.color"
                :outlined="items.outlined"
                rounded
                depressed
                block
                min-height="40"
                @click="items.action"
              >
                {{ items.text }}
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AppDialogInfo',
    computed: {
      dialogInfo() {
        return this.$store.state.shared.dialogInfo;
      }
    }
  };
</script>

<style lang="scss">
  .app-dialog-info {
    position: relative;
    overflow-y: inherit !important;
    .content {
      max-width: 350px;
    }
    .icon {
      font-size: 8rem !important;
    }
    #popupcard {
      border-radius: 20px;
    }
    #popupcard .content {
      max-width: 400px;
    }
    .requestover {
      margin: 20px 0;
      text-align: center;
    }
    .requestoverRemark {
      color: #cc6558;
      font-size: 12px;
      line-height: 1.2;
      text-align: center;
      font-weight: 500;
      max-width: 250px;
      margin: auto;
    }
    .toplogo {
      width: 150px;
      text-align: center;
      display: block;
      border-radius: 50% !important;
      text-align: center;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: -55px;
    }
    .two-btn {
      position: absolute;
      left: 40px;
      right: 40px;
      margin-top: 30px;
    }
    .two-btn img {
      width: 60px;
      padding: 10px;
    }
    .two-btn div:first-child a {
      background-color: rgb(255, 255, 255, 0.3);
      height: 60px;
      display: inline-block;
      border-radius: 50%;
    }
    .two-btn div:nth-child(2) a {
      background-color: #cc6558;
      height: 60px;
      display: inline-block;
      border-radius: 50%;
    }
    @media (max-width: 599px) {
      .two-btn {
        margin-top: 50px;
      }
    }
  }
</style>
