import { RECIPE } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';
import fileType from '@/core/filters/fileType.filter';
import currencyFormat from '@/core/filters/currency-format.filter';

/* Define Module Name */
const MODULE_NAME = 'recipe/';

/* Define Action Name */
const A_GET_TOP_RECIPE = 'getTopRecipe';
const A_GET_RECIPES = 'getRecipes';
const A_GET_RECIPES_BASIC = 'getRecipesBasic';
const A_GET_RECIPE = 'getRecipe';
const A_GET_RECIPE_MOBILE_LINK = 'getRecipeMobileLink';

/* Define Reset State Action Name */
const A_INITIAL_GET_TOP_RECIPE_STATE = 'initialGetTopRecipeState';
const A_INITIAL_GET_RECIPES_STATE = 'initialGetRecipesState';
const A_INITIAL_GET_RECIPES_BASIC_STATE = 'initialGetRecipesBasicState';
const A_INITIAL_GET_RECIPE_STATE = 'initialGetRecipeState';
const A_INITIAL_GET_RECIPE_MOBILE_LINK_STATE =
  'initialGetRecipeMobileLinkState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_TOP_RECIPE = 'setGetTopRecipe';
const M_GET_RECIPES = 'setGetRecipes';
const M_GET_RECIPES_BASIC = 'setGetRecipesBasic';
const M_GET_RECIPE = 'setGetRecipe';
const M_GET_RECIPE_MOBILE_LINK = 'setGetRecipeMobileLink';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_TOP_RECIPE_STATE = 'setInitialGetTopRecipeState';
const M_INITIAL_GET_RECIPES_STATE = 'setInitialGetRecipesState';
const M_INITIAL_GET_RECIPES_BASIC_STATE = 'setInitialGetRecipesBasicState';
const M_INITIAL_GET_RECIPE_STATE = 'setInitialGetRecipeState';
const M_INITIAL_GET_RECIPE_MOBILE_LINK_STATE =
  'setInitialGetRecipeMobileLinkState';

/* Define Export Name */
export const RECIPE_GET_TOP_RECIPE = MODULE_NAME + A_GET_TOP_RECIPE;
export const RECIPE_GET_RECIPES = MODULE_NAME + A_GET_RECIPES;
export const RECIPE_GET_RECIPES_BASIC = MODULE_NAME + A_GET_RECIPES_BASIC;
export const RECIPE_GET_RECIPE = MODULE_NAME + A_GET_RECIPE;
export const RECIPE_GET_RECIPE_MOBILE_LINK =
  MODULE_NAME + A_GET_RECIPE_MOBILE_LINK;

/* Define Reset State Name */
export const RECIPE_INITIAL_GET_TOP_RECIPE_STATE =
  MODULE_NAME + A_INITIAL_GET_TOP_RECIPE_STATE;
export const RECIPE_INITIAL_GET_RECIPES_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPES_STATE;
export const RECIPE_INITIAL_GET_RECIPES_BASIC_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPES_BASIC_STATE;
export const RECIPE_INITIAL_GET_RECIPE_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_STATE;
export const RECIPE_INITIAL_GET_RECIPE_MOBILE_LINK_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_MOBILE_LINK_STATE;

const state = {
  topRecipe: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  recipes: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  recipesBasic: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  recipe: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  recipeMobileLink: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_TOP_RECIPE]({ dispatch, commit }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    RECIPE.getTopRecipe()
      .then((response) => {
        result = response;
        commit(M_GET_TOP_RECIPE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_TOP_RECIPE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPES]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    RECIPE.getRecipes(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPES, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPES, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPES_BASIC]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    RECIPE.getRecipes(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPES_BASIC, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPES_BASIC, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE]({ dispatch, commit }, { id }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    RECIPE.getRecipe(id)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_MOBILE_LINK]({ dispatch, commit }, { id }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    RECIPE.getRecipeMobileLink(id)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_MOBILE_LINK, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_MOBILE_LINK, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_TOP_RECIPE_STATE]({ commit }) {
    commit(M_INITIAL_GET_TOP_RECIPE_STATE);
  },
  [A_INITIAL_GET_RECIPES_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPES_STATE);
  },
  [A_INITIAL_GET_RECIPES_BASIC_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPES_BASIC_STATE);
  },
  [A_INITIAL_GET_RECIPE_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_STATE);
  },
  [A_INITIAL_GET_RECIPE_MOBILE_LINK_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_MOBILE_LINK_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_TOP_RECIPE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.topRecipe = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          description: d.description,
          userId: d.user_id,
          username: d.username,
          asset: d.asset,
          assetType: fileType(d.asset),
          category: d.category,
          categoryDescription: d.category_description,
          cookingLevel: d.cooking_level,
          cookingLevel_description: d.cooking_level_description,
          creatorName: d.creator_name,
          ecoPackOption: d.eco_pack_option,
          ecoPackOption_description: d.eco_pack_option_description,
          ingredient: d.ingredient,
          ingredients: d.ingredients,
          is_top: d.is_top,
          price: currencyFormat(d.price),
          priority: d.priority,
          recipeId: d.recipe_id,
          servePax: d.serve_pax,
          thumbnail: d.thumbnail
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.topRecipe = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_RECIPES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.recipes = {
        code: result.code,
        complete: true,
        data: d.map((x) => ({
          id: x.id,
          name: x.name,
          recipeId: x.recipe_id,
          username: x.username,
          userId: x.user_id,
          creatorName: x.store_name ?? x.username,
          category: x.category,
          categoryDescription: x.category_description,
          isTop: x.is_top,
          price: currencyFormat(x.price),
          priority: x.priority,
          thumbnail: x.thumbnail
        })),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.recipes = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_RECIPES_BASIC](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.recipesBasic = {
        code: result.code,
        complete: true,
        data: d.map((x) => ({
          id: x.id,
          name: x.name,
          recipeId: x.recipe_id,
          username: x.username,
          userId: x.user_id,
          creatorName: x.creator_name ?? x.username,
          category: x.category,
          categoryDescription: x.category_description,
          isTop: x.is_top,
          price: currencyFormat(x.price),
          priority: x.priority,
          thumbnail: x.thumbnail
        })),
        message: null
      };
    } else {
      state.recipesBasic = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_RECIPE](state, result) {
    if (result.code == 0) {
      let d = result.data;
      state.recipe = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          recipeId: d.recipe_id,
          username: d.username,
          userId: d.user_id,
          creatorName: d.creator_name ?? d.username,
          ingredient: d.ingredient,
          category: d.category,
          categoryDescription: d.category_description,
          description: d.description,
          ecoPackOption: d.eco_pack_option,
          isTop: d.is_top,
          price: currencyFormat(d.price),
          servePax: d.serve_pax,
          priority: d.priority,
          assetType: fileType(d.asset),
          thumbnail: d.thumbnail,
          asset: d.asset,
          cookingLevel: d.cooking_level,
          cookingLevelDescription: d.cooking_level_description
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.recipe = {
        code: result.code,
        complete: true,
        data: result.data,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_RECIPE_MOBILE_LINK](state, result) {
    let d = result.data;
    state.recipeMobileLink = {
      code: 0,
      complete: true,
      data: {
        previewLink: d.previewLink,
        shortLink: d.shortLink
      },
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_TOP_RECIPE_STATE](state) {
    state.topRecipe = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_RECIPES_STATE](state) {
    state.recipes = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_RECIPES_BASIC_STATE](state) {
    state.recipesBasic = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_RECIPE_STATE](state) {
    state.recipe = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_RECIPE_MOBILE_LINK_STATE](state) {
    state.recipeMobileLink = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
