<template>
  <v-overlay opacity="0.8" :value="isLoading" z-index="333">
    <v-img
      max-height="150"
      max-width="150"
      src="/assets/image/logo-animation.gif"
    ></v-img>
  </v-overlay>
</template>

<script>
  export default {
    name: 'AppLoadingLinear',
    computed: {
      isLoading() {
        return this.$store.state.shared.isLoading;
      }
    }
  };
</script>

<style lang="scss"></style>
