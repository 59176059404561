import { apiService } from '@/core/services';

export const LOOKUP = {
  getGlobalSetting
};

const URL = {
  API: 'api',
  V1: 'v1',
  LOOKUP: 'lookup',
  GLOBAL_SETTING: 'global-setting'
};

function getGlobalSetting(data) {
  let requestOptions = {
    params: {
      platform: data.platform
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.LOOKUP}/${URL.GLOBAL_SETTING}`,
    requestOptions
  );
}
