import { apiService } from '@/core/services';

export const USER_VOUCHER = {
  getUserVoucher,
  updateCheckUserVoucher
};

const URL = {
  API: 'api',
  V1: 'v1',
  USER_VOUCHERS: 'user-vouchers',
  VOUCHER_CODE_DETAIL: 'voucher-code-detail',
  VERIFY: 'verify'
};

function getUserVoucher(data) {
  let requestOptions = {
    params: {
      user_id: data.userId,
      voucher_code: data.voucherCode,
      voucher_id: data.voucherId,
      branch_name: data.branchName,
      branch_id: data.branchId,
      merchant_id: data.merchantId
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.USER_VOUCHERS}/${URL.VOUCHER_CODE_DETAIL}`,
    requestOptions
  );
}

function updateCheckUserVoucher(data) {
  let requestBody = {
    voucher_code: data.voucherCode,
    pin_verification: data.pinVerification,
    branch_name: data.branchName,
    branch_id: data.branchId,
    merchant_id: data.merchantId
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.USER_VOUCHERS}/${URL.VERIFY}`,
    requestBody
  );
}
