import { STUDIO } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'studio/';

/* Define Action Name */
const A_CREATE_BOOKING = 'createBooking';
const A_SET_CONTACT_NUMBER = 'setContactNumber';
const A_VERIFY_CONTACT_NUMBER = 'verifyContactNumber';

/* Define Reset State Action Name */
const A_INITIAL_CREATE_BOOKING_STATE = 'initialCreateBookingState';
const A_INITIAL_SET_CONTACT_NUMBER_STATE = 'initialSetContactNumberState';
const A_INITIAL_VERIFY_CONTACT_NUMBER_STATE = 'initialVerifyContactNumberState';

/* Define Getter Name */

/* Define Mutation Name */
const M_CREATE_BOOKING = 'setCreateBooking';
const M_SET_CONTACT_NUMBER = 'setSetContactNumber';
const M_VERIFY_CONTACT_NUMBER = 'setVerifyContactNumber';

/* Define Reset State Mutation Name */
const M_INITIAL_CREATE_BOOKING_STATE = 'setInitialCreateBookingState';
const M_INITIAL_SET_CONTACT_NUMBER_STATE = 'setInitialSetContactNumberState';
const M_INITIAL_VERIFY_CONTACT_NUMBER_STATE =
  'setInitialVerifyContactNumberState';

/* Define Export Name */
export const STUDIO_CREATE_BOOKING = MODULE_NAME + A_CREATE_BOOKING;
export const STUDIO_SET_CONTACT_NUMBER = MODULE_NAME + A_SET_CONTACT_NUMBER;
export const STUDIO_VERIFY_CONTACT_NUMBER =
  MODULE_NAME + A_VERIFY_CONTACT_NUMBER;

/* Define Reset State Name */
export const STUDIO_INITIAL_CREATE_BOOKING_STATE =
  MODULE_NAME + A_INITIAL_CREATE_BOOKING_STATE;
export const STUDIO_INITIAL_SET_CONTACT_NUMBER_STATE =
  MODULE_NAME + A_INITIAL_SET_CONTACT_NUMBER_STATE;
export const STUDIO_INITIAL_VERIFY_CONTACT_NUMBER_STATE =
  MODULE_NAME + A_INITIAL_VERIFY_CONTACT_NUMBER_STATE;

const state = {
  createBooking: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setContactNumber: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  verifyContactNumber: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_CREATE_BOOKING]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await STUDIO.createBooking(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_BOOKING, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_BOOKING, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_SET_CONTACT_NUMBER]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await STUDIO.setContactNumber(data)
      .then((response) => {
        result = response;
        commit(M_SET_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_VERIFY_CONTACT_NUMBER]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await STUDIO.verifyContactNumber(data)
      .then((response) => {
        result = response;
        commit(M_VERIFY_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_VERIFY_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CREATE_BOOKING_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_BOOKING_STATE);
  },
  [A_INITIAL_SET_CONTACT_NUMBER_STATE]({ commit }) {
    commit(M_INITIAL_SET_CONTACT_NUMBER_STATE);
  },
  [A_INITIAL_VERIFY_CONTACT_NUMBER_STATE]({ commit }) {
    commit(M_INITIAL_VERIFY_CONTACT_NUMBER_STATE);
  }
};

// Mutations
const mutations = {
  [M_CREATE_BOOKING](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.createBooking = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createBooking = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_SET_CONTACT_NUMBER](state, result) {
    state.setContactNumber = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_VERIFY_CONTACT_NUMBER](state, result) {
    if (result.code == 0) {
      let d = result.data;

      state.verifyContactNumber = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          username: d.username,
          email: d.email,
          contactNumber: d.contact_number,
          phoneVerifiedAt: d.phone_verified_at,
          isActive: d.is_active,
          isCreator: d.is_creator,
          lastLoginAt: d.last_login_at,
          bio: d.bio,
          birthday: d.birthday,
          profilePhotoUrl: d.profile_photo_url,
          profileBackgroundUrl: d.profile_background_url,
          creatorApplicationStatus: d.creator_application_status
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.verifyContactNumber = {
        code: result.code,
        complete: true,
        data: result.data,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_CREATE_BOOKING_STATE](state) {
    state.createBooking = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_CONTACT_NUMBER_STATE](state) {
    state.setContactNumber = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_VERIFY_CONTACT_NUMBER_STATE](state) {
    state.verifyContactNumber = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
