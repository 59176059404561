import { AUTH } from '@/core/api';
import { jwtHelper, apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'auth/';

/* Define Action Name */
const A_SIGNUP = 'registration';
const A_SET_CONTACT_NUMBER = 'setContactNumber';
const A_VERIFY_CONTACT_NUMBER = 'verifyContactNumber';

/* Define Reset State Action Name */
const A_INITIAL_SIGNUP_STATE = 'initialRegistrationState';
const A_INITIAL_SET_CONTACT_NUMBER_STATE = 'initialSetContactNumberState';
const A_INITIAL_VERIFY_CONTACT_NUMBER_STATE = 'initialVerifyContactNumberState';

/* Define Getter Name */

/* Define Mutation Name */
const M_SIGNUP = 'setRegistration';
const M_SET_CONTACT_NUMBER = 'setSetContactNumber';
const M_VERIFY_CONTACT_NUMBER = 'setVerifyContactNumber';

/* Define Reset State Mutation Name */
const M_INITIAL_SIGNUP_STATE = 'setInitialRegistrationState';
const M_INITIAL_SET_CONTACT_NUMBER_STATE = 'setInitialSetContactNumberState';
const M_INITIAL_VERIFY_CONTACT_NUMBER_STATE =
  'setInitialVerifyContactNumberState';

/* Define Export Name */
export const AUTH_SIGNUP = MODULE_NAME + A_SIGNUP;
export const AUTH_SET_CONTACT_NUMBER = MODULE_NAME + A_SET_CONTACT_NUMBER;
export const AUTH_VERIFY_CONTACT_NUMBER = MODULE_NAME + A_VERIFY_CONTACT_NUMBER;

/* Define Reset State Name */
export const AUTH_INITIAL_SIGNUP_STATE = MODULE_NAME + A_INITIAL_SIGNUP_STATE;
export const AUTH_INITIAL_SET_CONTACT_NUMBER_STATE =
  MODULE_NAME + A_INITIAL_SET_CONTACT_NUMBER_STATE;
export const AUTH_INITIAL_VERIFY_CONTACT_NUMBER_STATE =
  MODULE_NAME + A_INITIAL_VERIFY_CONTACT_NUMBER_STATE;

const state = {
  signup: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setContactNumber: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  verifyContactNumber: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_SIGNUP]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await AUTH.signup(data)
      .then((response) => {
        result = response;
        commit(M_SIGNUP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SIGNUP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_SET_CONTACT_NUMBER]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await AUTH.setContactNumber(data)
      .then((response) => {
        result = response;
        commit(M_SET_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_VERIFY_CONTACT_NUMBER]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await AUTH.verifyContactNumber(data)
      .then((response) => {
        result = response;
        commit(M_VERIFY_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_VERIFY_CONTACT_NUMBER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_SIGNUP_STATE]({ commit }) {
    commit(M_INITIAL_SIGNUP_STATE);
  },
  [A_INITIAL_SET_CONTACT_NUMBER_STATE]({ commit }) {
    commit(M_INITIAL_SET_CONTACT_NUMBER_STATE);
  },
  [A_INITIAL_VERIFY_CONTACT_NUMBER_STATE]({ commit }) {
    commit(M_INITIAL_VERIFY_CONTACT_NUMBER_STATE);
  }
};

// Mutations
const mutations = {
  [M_SIGNUP](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let user = result.data.user;

      state.signup = {
        code: result.code,
        complete: true,
        data: {
          token: d.token,
          user: {
            id: user.id,
            username: user.username,
            email: user.email,
            contactNumber: user.contact_number,
            phoneVerified_at: user.phone_verified_at,
            isActive: user.is_active,
            isCreator: user.is_creator,
            lastLoginAt: user.last_login_at,
            bio: user.bio,
            birthday: user.birthday,
            profilePhotoUrl: user.profile_photo_url,
            profileBackgroundUrl: user.profile_background_url,
            creatorApplicationStatus: user.creator_application_status
          }
        },
        message: apiHelper.sortOutMessage(result.message)
      };

      jwtHelper.setToken(d.token);
    } else {
      state.signup = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_SET_CONTACT_NUMBER](state, result) {
    state.setContactNumber = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_VERIFY_CONTACT_NUMBER](state, result) {
    if (result.code == 0) {
      let d = result.data;

      state.verifyContactNumber = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          username: d.username,
          email: d.email,
          contactNumber: d.contact_number,
          phoneVerifiedAt: d.phone_verified_at,
          isActive: d.is_active,
          isCreator: d.is_creator,
          lastLoginAt: d.last_login_at,
          bio: d.bio,
          birthday: d.birthday,
          profilePhotoUrl: d.profile_photo_url,
          profileBackgroundUrl: d.profile_background_url,
          creatorApplicationStatus: d.creator_application_status
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.verifyContactNumber = {
        code: result.code,
        complete: true,
        data: result.data,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_SIGNUP_STATE](state) {
    state.signup = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_CONTACT_NUMBER_STATE](state) {
    state.setContactNumber = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_VERIFY_CONTACT_NUMBER_STATE](state) {
    state.verifyContactNumber = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
