import { ROUTE_NAME } from '@/core/constants';

const routes = [
  {
    path: '/',
    name: ROUTE_NAME.HOME,
    component: () => import('@/views/pages/Home.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/signup',
    name: ROUTE_NAME.SIGNUP,
    component: () => import('@/views/pages/Signup.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/register-complete',
    name: ROUTE_NAME.REGISTER_COMPLETE,
    component: () => import('@/views/pages/RegisterComplete.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/qr-Link',
    name: ROUTE_NAME.QR_LINK,
    component: () => import('@/views/pages/QRLink.vue'),
    meta: { layout: 'BlankLayout' }
  },
  {
    path: '/scan',
    name: ROUTE_NAME.SCAN,
    component: () => import('@/views/pages/scan.vue'),
    meta: { layout: 'BlankLayout' }
  },
  {
    path: '/voucher-redeem',
    name: ROUTE_NAME.VOUCHER_REDEEM,
    component: () => import('@/views/pages/voucher/VerifyRedeem.vue'),
    meta: { layout: 'layout' }
  },
  {
    path: '/about-us',
    name: ROUTE_NAME.ABOUT_US,
    redirect: { name: ROUTE_NAME.FAQ },
    component: () => import('@/views/pages/about-us/AboutUs.vue'),
    children: [
      {
        path: 'contact-us',
        name: ROUTE_NAME.CONTACT_US,
        component: () => import('@/views/pages/about-us/ContactUs.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'cookx',
        name: ROUTE_NAME.ABOUT_COOKX,
        component: () => import('@/views/pages/about-us/AboutCookX.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'selling',
        name: ROUTE_NAME.ABOUT_SELLING,
        component: () => import('@/views/pages/about-us/AboutSelling.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'studio',
        name: ROUTE_NAME.ABOUT_STUDIO,
        component: () => import('@/views/pages/about-us/AboutStudio.vue'),
        meta: { layout: 'layout' }
      }
      // {
      //   path: 'careers',
      //   name: ROUTE_NAME.CAREERS,
      //   component: () => import('@/views/pages/about-us/Careers.vue'),
      //   meta: { layout: 'layout' }
      // }
    ]
  },
  {
    path: '/press-coverage',
    name: ROUTE_NAME.PRESS_COVERAGE,
    redirect: { name: ROUTE_NAME.PRESS_COVERAGE_LIST },
    component: () => import('@/views/pages/pressCoverage/PressCoverage.vue'),
    children: [
      {
        path: 'press-coverage-list',
        name: ROUTE_NAME.PRESS_COVERAGE_LIST,
        component: () =>
          import('@/views/pages/pressCoverage/PressCoverageList.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'press-coverage-detail/:id',
        name: ROUTE_NAME.PRESS_COVERAGE_DETAIL,
        props: true,
        component: () =>
          import('@/views/pages/pressCoverage/PressCoverageDetail.vue'),
        meta: { layout: 'layout' }
      }
    ]
  },
  {
    path: '/support',
    name: ROUTE_NAME.SUPPORT,
    redirect: { name: ROUTE_NAME.FAQ },
    component: () => import('@/views/pages/support/Support.vue'),
    children: [
      {
        path: 'faq',
        name: ROUTE_NAME.FAQ,
        component: () => import('@/views/pages/support/FAQ.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'terms-of-use',
        name: ROUTE_NAME.TERMS_OF_USE,
        component: () => import('@/views/pages/support/TermsOfUse.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'privacy-policy',
        name: ROUTE_NAME.PRIVACY_POLICY,
        component: () => import('@/views/pages/support/PrivacyPolicy.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'terms-and-conditions',
        name: ROUTE_NAME.TERMS_AND_CONDITIONS,
        component: () => import('@/views/pages/support/TermsAndConditions.vue'),
        meta: { layout: 'layout' }
      }
    ]
  },
  {
    path: '/recipe',
    name: ROUTE_NAME.RECIPE,
    redirect: { name: ROUTE_NAME.RECIPE_LIST },
    component: () => import('@/views/pages/recipe/Recipe.vue'),
    meta: { layout: 'layout' },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.RECIPE_LIST,
        component: () => import('@/views/pages/recipe/RecipeBasicList.vue'),
        meta: { layout: 'layout' }
      },
      {
        path: 'bakery',
        name: ROUTE_NAME.RECIPE_BAKERY,
        component: () => import('@/views/pages/recipe/bakery/RecipeBakery.vue'),
        meta: { layout: 'layout' },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.RECIPE_BAKERY_LIST,
            component: () =>
              import('@/views/pages/recipe/bakery/RecipeBakeryList.vue'),
            meta: { layout: 'layout' },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.RECIPE_BAKERY_DETAILS,
            component: () =>
              import('@/views/pages/recipe/bakery/RecipeBakeryDetails.vue'),
            meta: { layout: 'layout' },
            children: []
          }
        ]
      },
      {
        path: 'asian',
        name: ROUTE_NAME.RECIPE_ASIAN,
        component: () => import('@/views/pages/recipe/asian/RecipeAsian.vue'),
        meta: { layout: 'layout' },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.RECIPE_ASIAN_LIST,
            component: () =>
              import('@/views/pages/recipe/asian/RecipeAsianList.vue'),
            meta: { layout: 'layout' },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.RECIPE_ASIAN_DETAILS,
            component: () =>
              import('@/views/pages/recipe/asian/RecipeAsianDetails.vue'),
            meta: { layout: 'layout' },
            children: []
          }
        ]
      },
      {
        path: 'western',
        name: ROUTE_NAME.RECIPE_WESTERN,
        component: () =>
          import('@/views/pages/recipe/western/RecipeWestern.vue'),
        meta: { layout: 'layout' },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.RECIPE_WESTERN_LIST,
            component: () =>
              import('@/views/pages/recipe/western/RecipeWesternList.vue'),
            meta: { layout: 'layout' },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.RECIPE_WESTERN_DETAILS,
            component: () =>
              import('@/views/pages/recipe/western/RecipeWesternDetails.vue'),
            meta: { layout: 'layout' },
            children: []
          }
        ]
      }
    ]
  },
  {
    path: '/',
    redirect: { name: ROUTE_NAME.HOME }
  },
  {
    path: '*',
    redirect: { name: ROUTE_NAME.HOME }
  }
];

export default routes;
