import { CREATOR_APPLICATION } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'creatorApplication/';

/* Define Action Name */
const A_INIT_APPLICATION = 'initApplication';
const A_GET_APPLICATION = 'getApplication';
const A_UPLOAD_DOCUMENT = 'uploadDocument';
const A_SUBMIT_APPLICATION = 'submitApplication';

/* Define Reset State Action Name */
const A_INITIAL_INIT_APPLICATION_STATE = 'initialInitApplicationState';
const A_INITIAL_GET_APPLICATION_STATE = 'initialGetApplicationState';
const A_INITIAL_UPLOAD_DOCUMENT_STATE = 'initialUploadDocumentState';
const A_INITIAL_SUBMIT_APPLICATION_STATE = 'initialSubmitApplicationState';

/* Define Getter Name */

/* Define Mutation Name */
const M_INIT_APPLICATION = 'setInitApplication';
const M_GET_APPLICATION = 'setApplication';
const M_UPLOAD_DOCUMENT = 'setUploadDocument';
const M_SUBMIT_APPLICATION = 'setSubmitApplication';

/* Define Reset State Mutation Name */
const M_INITIAL_INIT_APPLICATION_STATE = 'setInitialInitApplicationState';
const M_INITIAL_GET_APPLICATION_STATE = 'setInitialGetApplicationState';
const M_INITIAL_UPLOAD_DOCUMENT_STATE = 'setInitialUploadDocumentState';
const M_INITIAL_SUBMIT_APPLICATION_STATE = 'setInitialSubmitApplicationState';

/* Define Export Name */
export const CREATOR_APPLICATION_INIT_APPLICATION =
  MODULE_NAME + A_INIT_APPLICATION;
export const CREATOR_APPLICATION_GET_APPLICATION =
  MODULE_NAME + A_GET_APPLICATION;
export const CREATOR_APPLICATION_UPLOAD_DOCUMENT =
  MODULE_NAME + A_UPLOAD_DOCUMENT;
export const CREATOR_APPLICATION_SUBMIT_APPLICATION =
  MODULE_NAME + A_SUBMIT_APPLICATION;

/* Define Reset State Name */
export const CREATOR_APPLICATION_INITIAL_INIT_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_INIT_APPLICATION_STATE;
export const CREATOR_APPLICATION_INITIAL_GET_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_GET_APPLICATION_STATE;
export const CREATOR_APPLICATION_INITIAL_UPLOAD_DOCUMENT_STATE =
  MODULE_NAME + A_INITIAL_UPLOAD_DOCUMENT_STATE;
export const CREATOR_APPLICATION_INITIAL_SUBMIT_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_SUBMIT_APPLICATION_STATE;

const state = {
  initApplication: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  application: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  uploadDocument: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  submitApplication: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_INIT_APPLICATION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.initApplication(data)
      .then((response) => {
        let result = response;
        commit(M_INIT_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_INIT_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_APPLICATION]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.getApplication()
      .then((response) => {
        let result = response;
        commit(M_GET_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPLOAD_DOCUMENT]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.uploadDocument(data)
      .then((response) => {
        let result = response;
        commit(M_UPLOAD_DOCUMENT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPLOAD_DOCUMENT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SUBMIT_APPLICATION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.submitApplication(data)
      .then((response) => {
        let result = response;
        commit(M_SUBMIT_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_SUBMIT_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_INIT_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_INIT_APPLICATION_STATE);
  },
  [A_INITIAL_GET_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_GET_APPLICATION_STATE);
  },
  [A_INITIAL_UPLOAD_DOCUMENT_STATE]({ commit }) {
    commit(M_INITIAL_UPLOAD_DOCUMENT_STATE);
  },
  [A_INITIAL_SUBMIT_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_SUBMIT_APPLICATION_STATE);
  }
};

// Mutations
const mutations = {
  [M_INIT_APPLICATION](state, result) {
    state.initApplication = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_APPLICATION](state, result) {
    if (result.code == 0) {
      let d = result.data;

      state.application = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          userId: d.user_id,
          username: d.username,
          storeName: d.store_name,
          profileImage: d.profile_image,
          backgroundImage: d.background_image,
          hashtag: d.hashtag,
          submittedAt: d.submitted_at,
          approvedAt: d.approved_at,
          rejectedAt: d.rejected_at,
          createdAt: d.created_at,
          updatedAt: d.updated_at,
          businessUrl: d.business_url,
          documents: d.documents.map((x) => {
            return {
              uuid: x.uuid,
              originalUrl: x.original_url,
              documentType: x.document_type
            };
          })
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.application = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPLOAD_DOCUMENT](state, result) {
    state.uploadDocument = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_SUBMIT_APPLICATION](state, result) {
    state.submitApplication = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_INIT_APPLICATION_STATE](state) {
    state.initApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_APPLICATION_STATE](state) {
    state.application = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPLOAD_DOCUMENT_STATE](state) {
    state.uploadDocument = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SUBMIT_APPLICATION_STATE](state) {
    state.submitApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
