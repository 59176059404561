<template>
  <div class="wh-100">
    <NavigationBar></NavigationBar>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
        <!-- start:: Help Center -->
        <!-- end:: Help Center -->
      </transition>
      <a class="helpIcon" href="https://wa.me/60127790406" target="_blank">
        <img src="/assets/image/home/help.png" alt="" />
      </a>
    </v-main>
    <Footer></Footer>
  </div>
</template>

<script>
  import Footer from '@/views/layout/Footer';
  import NavigationBar from '@/views/layout/NavigationBar';
  import {
    PLATFORM_INFO_GET_PLATFORM_INFO,
    PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE
  } from '@/core/store/platform-info.module';
  import {
    CONSTANTS_GET_CONSTANTS,
    CONSTANTS_INITIAL_CONSTANTS_STATE
  } from '@/core/store/constants.module';

  export default {
    name: 'Layout',
    components: {
      Footer,
      NavigationBar
    },
    computed: {
      platFormInfoComplete() {
        return this.$store.state.platformInfo.platformInfo.complete;
      },
      constantsComplete() {
        return this.$store.state.constants.constants.complete;
      }
    },
    watch: {
      platFormInfoComplete() {
        let response = this.$store.state.platformInfo.platformInfo;

        if (response.complete) {
          this.platFormInfoCompleteAction(response);
        }
      },
      constantsComplete() {
        let response = this.$store.state.constants.constants;

        if (response.complete) {
          this.constantsCompleteAction(response);
        }
      }
    },
    async created() {
      await this.initLayout();
    },
    methods: {
      constantsCompleteAction(response) {
        if (response.code != 0) {
          console.log(response);
        }
        this.initialGetConstantsState();
      },
      platFormInfoCompleteAction(response) {
        if (response.code != 0) {
          // console.log(response);
          response = 0;
        }
        this.initialGetPlatFormInfoState();
      },
      async getConstants() {
        await this.$store.dispatch(CONSTANTS_GET_CONSTANTS);
      },
      initialGetConstantsState() {
        this.$store.dispatch(CONSTANTS_INITIAL_CONSTANTS_STATE);
      },
      initialGetPlatFormInfoState() {
        this.$store.dispatch(PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE);
      },
      getPlatFormInfo() {
        this.$store.dispatch(PLATFORM_INFO_GET_PLATFORM_INFO);
      },
      async initLayout() {
        await this.getConstants();
        this.getPlatFormInfo();
      }
    }
  };
</script>

<style></style>
