import { COMPANY_WEB } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'companyWeb/';

/* Define Action Name */
const A_SUBMIT_EMAIL_SUBSCRIPTION = 'submitEmailSubscription';
const A_SUBMIT_CONTACT_APPLICATION = 'submitContactApplication';

/* Define Reset State Action Name */
const A_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE =
  'initialSubmitEmailSubscriptionState';
const A_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE =
  'initialSubmitContactApplicationState';

/* Define Getter Name */

/* Define Mutation Name */
const M_SUBMIT_EMAIL_SUBSCRIPTION = 'setSubmitEmailSubscription';
const M_SUBMIT_CONTACT_APPLICATION = 'setSubmitContactApplication';

/* Define Reset State Mutation Name */
const M_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE =
  'setInitialSubmitEmailSubscriptionState';
const M_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE =
  'setInitialSubmitContactApplicationState';

/* Define Export Name */
export const COMPANY_WEB_SUBMIT_EMAIL_SUBSCRIPTION =
  MODULE_NAME + A_SUBMIT_EMAIL_SUBSCRIPTION;
export const COMPANY_WEB_SUBMIT_CONTACT_APPLICATION =
  MODULE_NAME + A_SUBMIT_CONTACT_APPLICATION;

/* Define Reset State Name */
export const COMPANY_WEB_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE =
  MODULE_NAME + A_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE;
export const COMPANY_WEB_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE;

const state = {
  submitEmailSubscription: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  submitContactApplication: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_SUBMIT_EMAIL_SUBSCRIPTION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    COMPANY_WEB.submitEmailSubscription(data)
      .then((response) => {
        let result = response;
        commit(M_SUBMIT_EMAIL_SUBSCRIPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_SUBMIT_EMAIL_SUBSCRIPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SUBMIT_CONTACT_APPLICATION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    COMPANY_WEB.submitContactApplication(data)
      .then((response) => {
        let result = response;
        commit(M_SUBMIT_CONTACT_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_SUBMIT_CONTACT_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE]({ commit }) {
    commit(M_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE);
  },
  [A_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE);
  }
};

// Mutations
const mutations = {
  [M_SUBMIT_EMAIL_SUBSCRIPTION](state, result) {
    state.submitEmailSubscription = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_SUBMIT_CONTACT_APPLICATION](state, result) {
    state.submitContactApplication = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE](state) {
    state.submitEmailSubscription = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE](state) {
    state.submitContactApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
