import { apiService } from '@/core/services';

export const PRESS_COVERAGE = {
  getPressCoverages,
  getPressCoverage
  // getPressCoverageImg
};

const URL = {
  API: 'api',
  V1: 'v1',
  PRESS_COVERAGE: 'press-coverage',
  SHOW: 'show',
  LOGO: 'logo'
};

function getPressCoverages() {
  let requestOptions = {
    params: {
      // per_page: data.perPage,
      // page: data.page
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.PRESS_COVERAGE}`,
    requestOptions
  );
}

function getPressCoverage(id) {
  let requestOptions = {
    params: {
      // per_page: data.perPage,
      // page: data.page
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.PRESS_COVERAGE}/${id}`,
    requestOptions
  );
}

// function getPressCoverageImg() {
//   let requestOptions = {
//     params: {
//       // per_page: data.perPage,
//       // page: data.page
//     }
//   };
//   // api-dev.cook-x.com/api/v1/press-coverage/show/logo
//   return apiService().get(
//     `/${URL.API}/${URL.V1}/${URL.PRESS_COVERAGE}/${id}`,
//     requestOptions
//   );
// }
