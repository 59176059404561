import { ROUTE_NAME } from '@/core/constants';

export const NAVIGATION_LIST = [
  {
    path: '/cookx',
    routeName: ROUTE_NAME.ABOUT_COOKX,
    name: 'cooking',
    isActive: true
  },
  {
    path: '/selling',
    routeName: ROUTE_NAME.ABOUT_SELLING,
    name: 'selling',
    isActive: true
  },
  {
    path: '/studio',
    routeName: ROUTE_NAME.ABOUT_STUDIO,
    name: 'studio',
    isActive: true
  },
  {
    path: '/recipe',
    routeName: ROUTE_NAME.RECIPE,
    name: 'recipe',
    isActive: true
  }
];
