import { apiService } from '@/core/services';

export const PLATFORM_INFO = {
  getPlatformInfo
};

const URL = {
  API: 'api',
  V1: 'v1',
  PLATFORM: 'platform'
};

function getPlatformInfo() {
  return apiService(null, false, false).get(
    `/${URL.API}/${URL.V1}/${URL.PLATFORM}`
  );
}
