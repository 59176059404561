import { apiService } from '@/core/services';

export const CREATOR_APPLICATION = {
  initApplication,
  getApplication,
  uploadDocument,
  submitApplication
};

const URL = {
  API: 'api',
  V1: 'v1',
  CREATOR_APPLICATION: 'creator-application',
  INIT_APPLICATION: 'init-application',
  UPLOAD_DOCUMENT: 'upload-document',
  SUBMIT_APPLICATION: 'submit-application'
};

function initApplication(data) {
  let formData = new FormData();

  formData.append('store_name', data.storeName);
  formData.append('creator_name', data.creatorName);
  formData.append('business_place_name', data.businessPlaceName);
  formData.append('business_address', data.businessAddress);
  formData.append('business_address_postcode', data.businessAddressPostcode);
  formData.append('business_address_city', data.businessAddressCity);
  formData.append('business_address_state', data.businessAddressState);
  formData.append('email', data.email);
  formData.append('contact_number', data.contactNumber);
  formData.append('latitude', data.latitude);
  formData.append('longitude', data.longitude);

  if (data.remark) formData.append('remark', data.remark);

  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CREATOR_APPLICATION}/${URL.INIT_APPLICATION}`,
    formData
  );
}

function getApplication() {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.CREATOR_APPLICATION}`);
}

function uploadDocument(data) {
  let formData = new FormData();

  formData.append('file', data.file);
  formData.append('type', data.type);

  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CREATOR_APPLICATION}/${URL.UPLOAD_DOCUMENT}`,
    formData
  );
}

function submitApplication(data) {
  let requestBody = {
    is_corporate: data.isCorporate
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CREATOR_APPLICATION}/${URL.SUBMIT_APPLICATION}`,
    requestBody
  );
}
