import { apiService } from '@/core/services';

export const STUDIO = {
  createBooking
};

const URL = {
  WEB: 'web',
  STUDIO_BOOKING: 'studio-booking'
};

function createBooking(data) {
  let requestBody = {
    person_in_charge: data.contactPersonName,
    contact_number: Number(6 + data.contactNumber),
    preferred_date: new Date(data.preferredDate).getTime()
  };
  return apiService().post(`/${URL.WEB}/${URL.STUDIO_BOOKING}`, requestBody);
}
