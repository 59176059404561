import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import shared from './shared.module';
import platformInfo from './platform-info.module';
import career from './career-module';
import lookup from './lookup.module';
import constants from './constants.module';
import auth from './auth.module';
import creatorApplication from './creator-application.module';
import companyWeb from './company-web.module';
import pressCoverage from './press-coverage.module';
import userVoucher from './user-voucher.module';
import studio from './studio.module';
import recipe from './recipe.module';

export default new Vuex.Store({
  modules: {
    shared,
    platformInfo,
    career,
    lookup,
    constants,
    auth,
    creatorApplication,
    companyWeb,
    pressCoverage,
    userVoucher,
    studio,
    recipe
  }
});
