<template>
  <v-radio
    :value="value"
    dark
    color="primary"
    on-icon="mdi-check-circle"
    off-icon="mdi-check-circle"
    class="app-radio"
    @click="$emit('input', value)"
  >
    <template v-slot:label>
      <slot />
    </template>
  </v-radio>
</template>

<script>
  export default {
    name: 'AppRadio',
    props: {
      value: {
        type: [String, Number]
      }
    }
  };
</script>

<style lang="scss">
  .app-radio {
    .v-input--selection-controls__input {
      .v-icon {
        font-size: 36px !important;
      }
    }
  }
</style>
