import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18n from '@/core/plugins/i18n.js';
import { I18N } from '@/core/constants';

Vue.use(VueI18n);

const { context, messages } = loadMessages();

// Load all locales and remember context
function loadMessages() {
  const context = require.context(
    '@/core/locales',
    true,
    /[a-z0-9-_]+\.json$/i
  );

  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key)
      }),
      {}
    );

  return { context, messages };
}

export default new VueI18n({
  locale: I18N.DEFAULT_LANGUAGE,
  fallbackLocale: I18N.DEFAULT_LANGUAGE,
  messages
});

if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}

/* Documentation 
https://kazupon.github.io/vue-i18n/guide/hot-reload.html#advanced-example */
