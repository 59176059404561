<template>
  <div class="email-subscription-field">
    <v-form ref="emailSubscriptionForm" class="d-md-flex">
      <AppFormField
        v-model="email"
        :dark="true"
        :rounded="true"
        :outlined="true"
        color="surface"
        :placeholder="$t('label.emailAddress')"
        :rules="formValidation.emailRules()"
        :class="addclass"
      ></AppFormField>
      <AppButton
        :xLarge="false"
        :minHeight="minHeight"
        :minWidth="minWidth"
        class="ml-sm-2 text-caption text-sm-body-2"
        @click="subscribeAction"
        >{{ changeTxt }}
      </AppButton>
    </v-form>
  </div>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import { formValidationHelper, i18nHelper } from '@/core/utils';
  import {
    COMPANY_WEB_SUBMIT_EMAIL_SUBSCRIPTION,
    COMPANY_WEB_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE
  } from '@/core/store/company-web.module';

  export default {
    name: 'EmailSubscriptionField',
    props: {
      addclass: {
        type: String,
        default: ''
      },
      changeTxt: {
        type: String,
        default: 'Follow Us'
      }
    },
    mixins: [common],
    data: () => ({
      formValidation: formValidationHelper,
      email: null
    }),
    computed: {
      submitEmailSubscriptionComplete() {
        return this.$store.state.companyWeb.submitEmailSubscription.complete;
      },
      minHeight() {
        if (!this.$vuetify.breakpoint.mobile) {
          return '56';
        } else {
          return '35';
        }
      },
      minWidth() {
        if (!this.$vuetify.breakpoint.mobile) {
          return '150';
        } else {
          return '160';
        }
      }
    },
    watch: {
      submitEmailSubscriptionComplete() {
        let response = this.$store.state.companyWeb.submitEmailSubscription;

        if (response.complete) {
          this.submitEmailSubscriptionCompleteAction(response);
        }
      }
    },
    methods: {
      submitEmailSubscriptionCompleteAction(response) {
        let type = response.code == 0 ? 'success' : 'error';
        let title = i18nHelper.getMessage('label.emailSubscription');
        let description = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.resetForm();
              }
            }
          ];
        } else {
          buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
        }

        this.openAppDialogInfo(type, title, description, buttons);
        this.initialSubmitEmailSubscription();
      },
      subscribeAction() {
        let data = {
          email: this.email
        };

        if (this.$refs.emailSubscriptionForm.validate()) {
          this.submitEmailSubscription(data);
        }
      },
      resetForm() {
        this.$refs.emailSubscriptionForm.reset();
      },
      submitEmailSubscription(data) {
        this.$store.dispatch(COMPANY_WEB_SUBMIT_EMAIL_SUBSCRIPTION, { data });
      },
      initialSubmitEmailSubscription() {
        this.$store.dispatch(
          COMPANY_WEB_INITIAL_SUBMIT_EMAIL_SUBSCRIPTION_STATE
        );
      }
    }
  };
</script>

ipt>

<style lang="scss">
  .email-subscription-field {
    .app-form-field {
      width: 100%;
    }
    .app-button.v-btn {
      padding: 0 16px !important;
      letter-spacing: 0.0892857143em !important;
    }
  }

  @media (max-width: 959px) {
    .email-subscription-field {
      .app-form-field {
        .v-input {
          font-size: 12px !important;
        }
        .v-input__slot {
          min-height: 45px !important;
        }
      }
    }
  }

  // .btn-submit .v-input__slot fieldset{
  //   background: rgb(255 255 255 / 25%);
  // }

  // .btn-submit input::placeholder {
  //   color: #fff !important;
  //   opacity: 1 !important;
  // }
  @media screen and (max-width: 920px) {
    // .btn-submit .v-input__slot fieldset{
    //   background: rgb(255 255 255 / 25%);
    //   max-height: 20px !important;
    // }
    // .btn-submit input{
    //   font-size: 1vw;
    // }

    // .btn-submit .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
    //   min-height: 5px !important;
    // }

    // .btn-submit .v-text-field--rounded > .v-input__control > .v-input__slot{
    //       padding: 0 10px;
    // }

    // .btn-submit .v-input input {
    //     max-height: 0px;
    // }

    .btn-submit button {
      background: var(--v-primary-base);
      color: #000;
      border-radius: 50px;
      padding: 1.2vw 2.5vw !important;
      // margin-left: 10px;
      height: 3.4vw !important;
      font-size: 1vw !important;
      /* letter-spacing: 0.8px; */
    }
  }
</style>
