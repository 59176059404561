import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import routes from './routes';

Vue.use(VueRouter);
Vue.use(Meta);

const scrollBehavior = () => {
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;
