import { apiService } from '@/core/services';

export const CONSTANTS = {
  getConstants
};

const URL = {
  API: 'api',
  V1: 'v1',
  CONSTANTS: 'constants'
};

function getConstants() {
  return apiService(null, false, false).get(
    `/${URL.API}/${URL.V1}/${URL.CONSTANTS}`
  );
}
