<template>
  <nav class="">
    <div class="navigation-bar d-flex justify-space-between align-center">
      <div class="text-center">
        <router-link :to="{ name: routeName.HOME }">
          <img class="main-logo" src="/assets/image/logo.png" alt="logo" />
        </router-link>
        <div
          class="text-capitalize"
          :class="`${sloganColor}--text`"
          style="font-size: 12px"
        >
          {{ $t('label.slogan') }}
        </div>
      </div>
      <template v-if="isMobile">
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="font-size: 9px"
              color="white"
              class="secondary--text rounded-xl font-weight-bold"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <img
                src="/assets/image/menu_mobile.png"
                width="30px"
                class="pr-2"
              />
              <span>MENU</span>
            </v-btn>
          </template>
          <v-list class="secondary--text rounded text-center font-weight-bold">
            <v-list-item
              v-for="(i, index) in navigationList"
              :key="index"
              class="px-2"
            >
              <v-list-item-title>
                <router-link
                  v-if="i.isActive"
                  :key="index"
                  :to="{ name: i.routeName }"
                  style="font-size: 9px"
                  class="text-uppercase secondary--text"
                  :class="{ 'has-active': hasActivePage(i.path) }"
                >
                  {{ $t(`label.${i.name}`) }}
                </router-link>
                <hr class="mt-3" />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <div v-else class="tow-button">
        <template v-for="(i, index) in navigationList">
          <router-link
            v-if="i.isActive"
            :key="index"
            :to="{ name: i.routeName }"
            class="topbtn secondary--btn mx-md-3 text-uppercase smaller--text"
            :class="{ 'has-active': hasActivePage(i.path) }"
            >{{ $t(`label.${i.name}`) }}</router-link
          >
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
  import { NAVIGATION_LIST } from '@/core/services';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'NavigationBar',
    data: () => ({
      navigationList: NAVIGATION_LIST,
      routeName: ROUTE_NAME,
      sideDrawer: false
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      sloganColor() {
        let color = 'black';
        let currentPage = this.$route.path.split('/');
        let page = [
          'terms-and-conditions',
          'contact-us',
          'privacy-policy',
          'about-studio',
          'recipe',
          'signup',
          'register-complete',
          'terms-of-use'
        ];
        if (page.some((value) => currentPage.includes(value))) {
          color = 'white';
        }
        return color;
      }
    },
    created() {
      console.log('%cVersion 2.8.0v', 'color: #e7db47;');
    },
    watch: {},
    methods: {
      hasActivePage(match) {
        return this.$route['path'].indexOf(match) !== -1;
      }
    }
  };
</script>

<style></style>
