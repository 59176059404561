import axios from 'axios';
import { apiService } from '@/core/services';

export const RECIPE = {
  getTopRecipe,
  getRecipes,
  getRecipe,
  getRecipeMobileLink
};

const URL = {
  WEB: 'web',
  RECIPE_PAGES: 'recipe-pages',
  TOP: 'top'
};

function getTopRecipe() {
  return apiService().get(`/${URL.WEB}/${URL.RECIPE_PAGES}/${URL.TOP}`);
}

function getRecipes(data) {
  let requestOptions = {
    params: {
      category: data.category,
      per_page: data.perPage,
      page: data.currentPage
    }
  };
  return apiService().get(`/${URL.WEB}/${URL.RECIPE_PAGES}`, requestOptions);
}

function getRecipe(id) {
  return apiService().get(`/${URL.WEB}/${URL.RECIPE_PAGES}/${id}`);
}

function getRecipeMobileLink(id) {
  let requestBody = {
    dynamicLinkInfo: {
      domainUriPrefix: 'https://cookx.page.link',
      link: `https://cookx.asia/recipes?recipe_id=${id}`,
      androidInfo: {
        androidPackageName: 'com.cook.xpert.cookx',
        androidFallbackLink:
          'https://play.google.com/store/apps/details?id=com.cook.xpert.cookx'
      },
      iosInfo: {
        iosBundleId: 'com.cook.xpert.cookx',
        iosFallbackLink:
          'https://apps.apple.com/us/app/cookx-asia/id1560770482',
        iosIpadBundleId: 'com.cook.xpert.cookx',
        iosIpadFallbackLink:
          'https://apps.apple.com/us/app/cookx-asia/id1560770482'
      }
    }
  };

  return axios
    .post(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAGPUt6iXVXXrh25eSPo7Hk_kK180Gq9vQ',
      requestBody
    )
    .then((resp) => {
      return resp;
    });
}
