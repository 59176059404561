const SHARED = Object.freeze({
  DEFAULT_LOCALE: 'en',
  FALLBACK_LOCALE: 'en',
  DEFAULT_LAYOUT: 'Layout',
  COUNTRY_CALLING_CODE_MY: '60'
});

const CURRENCY = Object.freeze({
  MYR: 'myr'
});

const I18N = Object.freeze({
  DEFAULT_LANGUAGE: process.env.VUE_APP_I18N_LOCALE,
  LOCALE_STORAGE_KEY_NAME: 'language',
  AVAILABLE_LANGUAGES: ['en']
});

const JWT = Object.freeze({
  TOKEN: 'token',
  JWT_TOKEN_TYPE: 'Bearer ',
  SESSION_EXPIRATION_TIME: 'sessionExpirationTime',
  LAST_ACTIVITY_TIME: 'lastActivityTime'
});

const API = Object.freeze({
  BASE_API_URL: process.env.VUE_APP_BASE_API_URL,
  DATETIME_FROM_START_TIME: ' 00:00:00.000',
  DATETIME_TO_END_TIME: ' 23:59:59.999'
});

const LOCAL = Object.freeze({
  CONSTANTS: 'constants'
});

export { SHARED, I18N, JWT, API, LOCAL, CURRENCY };
