import { apiService } from '@/core/services';

export const CAREER = {
  getCareers,
  getCareerPosition
};

const URL = {
  ASSETS: 'assets',
  JSON: 'json',
  CAREERS: 'careers'
};

function getCareers() {
  return apiService('/').get(
    `/${URL.ASSETS}/${URL.JSON}/${URL.CAREERS}/${URL.CAREERS}.json`
  );
}

function getCareerPosition(id, positionId) {
  return apiService('/').get(
    `/${URL.ASSETS}/${URL.JSON}/${URL.CAREERS}/${id}/${positionId}.json`
  );
}
