<template>
  <v-btn
    rounded
    :x-large="xLarge"
    :disabled="disabled"
    :outlined="outlined"
    :color="color"
    :min-height="minHeight"
    :min-width="minWidth"
    :class="[textColor, { isFaded: faded }]"
    class="font-weight-bold px-12 app-button"
    @click="$emit('click')"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'AppButton',
    props: {
      type: {
        type: String,
        default: 'default'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      faded: {
        type: Boolean,
        default: false
      },
      xLarge: {
        type: Boolean,
        default: true
      },
      minHeight: {
        type: String,
        default: ''
      },
      minWidth: {
        type: String,
        default: ''
      }
    },
    computed: {
      color() {
        let color = {
          default: 'primary',
          recommended: 'tertiary',
          optional: 'secondary'
        };

        return this.disabled ? 'secondary' : color[this.type];
      },
      textColor() {
        let color = {
          default: 'secondary--text',
          recommended: 'surface--text',
          optional: 'secondary--text'
        };

        return this.disabled ? 'secondary--text' : color[this.type];
      },
      outlined() {
        return this.type == 'optional' || this.disabled;
      }
    }
  };
</script>

<style lang="scss">
  .app-button {
    &.isFaded {
      opacity: 0.8;
    }
  }
</style>
