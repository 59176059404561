import { CONSTANTS } from '@/core/api';
import { LOCAL } from '@/core/constants';
import { uiHelper, apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';
/* Define Module Name */
const MODULE_NAME = 'constants/';

/* Define Action Name */
const A_CONSTANTS = 'getConstants';
/* Define Reset State Action Name */
const A_INITIAL_CONSTANTS_STATE = 'initialConstantsState';

/* Define Getter Name */
const G_AVAILABLE_PERMISSIONS = 'availablePermissions';

/* Define Mutation Name */
const M_CONSTANTS = 'setConstants';
/* Define Reset State Mutation Name */
const M_INITIAL_CONSTANTS_STATE = 'setInitialConstantState';

/* Define Export Name */
export const CONSTANTS_GET_CONSTANTS = MODULE_NAME + A_CONSTANTS;
export const CONSTANTS_AVAILABLE_PERMISSIONS =
  MODULE_NAME + G_AVAILABLE_PERMISSIONS;

/* Define Reset State Name */
export const CONSTANTS_INITIAL_CONSTANTS_STATE =
  MODULE_NAME + A_INITIAL_CONSTANTS_STATE;

const state = {
  constants: {
    code: 0,
    complete: false,
    data: {
      deviceType: [],
      creatorApplicationDocumentType: [],
      appInfo: []
    },
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_CONSTANTS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await CONSTANTS.getConstants()
      .then((response) => {
        let result = response;
        commit(M_CONSTANTS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CONSTANTS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CONSTANTS_STATE]({ commit }) {
    commit(M_INITIAL_CONSTANTS_STATE);
  }
};

// Mutations
const mutations = {
  [M_CONSTANTS](state, result) {
    if (result.code == 0) {
      let d = result.data;

      state.constants = {
        code: result.code,
        complete: true,
        data: {
          deviceType: d.device_type,
          creatorApplicationDocumentType: d.creator_application_document_type,
          appInfo: d.app_info
        },
        message: null
      };

      uiHelper.setLocalStorage(
        LOCAL.CONSTANTS,
        JSON.stringify(state.constants.data)
      );
    } else {
      state.user = {
        code: result.code,
        complete: false,
        data: {
          deviceType: [],
          creatorApplicationDocumentType: [],
          appInfo: []
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_CONSTANTS_STATE](state) {
    state.constants = {
      code: 0,
      complete: false,
      data: {},
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
