import moment from 'moment';

export default function dateTimeFormat(date) {
  //check dateTime
  // var localdate = new Date(date);
  // localdate = localdate.toLocaleString('zh-CN');
  // console.log(localdate);
  // console.log(date);

  if (!['', null, undefined].includes(date)) {
    return moment(date).format('DD MMM YYYY h:mm A');
  } else {
    return '-';
  }
}
