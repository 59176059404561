import { apiService } from '@/core/services';

export const AUTH = {
  signup,
  setContactNumber,
  verifyContactNumber
};

const URL = {
  API: 'api',
  V1: 'v1',
  V2: 'v2',
  AUTH: 'auth',
  SIGNUP: 'signup',
  SET_CONTACT_NUMBER: 'set-contact-number',
  VERIFY_CONTACT_NUMBER: 'verify-contact-number'
};

function signup(data) {
  let referralCode = data.referralCode;
  let requestBody = {
    // username: data.username,
    // email: data.email,
    password: data.password,
    password_confirmation: data.passwordConfirmation,
    contact_number: data.contactNumber
  };

  if (referralCode) requestBody.referral_code = referralCode;
  return apiService(null, false).post(
    `/${URL.API}/${URL.V2}/${URL.AUTH}/${URL.SIGNUP}`,
    requestBody
  );
}

function setContactNumber(data) {
  let requestBody = {
    contact_number: data.contactNumber
  };
  return apiService().post(
    `/${URL.API}/${URL.V2}/${URL.AUTH}/${URL.SET_CONTACT_NUMBER}`,
    requestBody
  );
}

function verifyContactNumber(data) {
  let requestBody = {
    contact_number: data.contactNumber,
    passcode: data.passcode
  };
  return apiService().post(
    `/${URL.API}/${URL.V2}/${URL.AUTH}/${URL.VERIFY_CONTACT_NUMBER}`,
    requestBody
  );
}
