import { CAREER } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'career/';

/* Define Action Name */
const A_GET_CAREERS = 'getCareers';
const A_GET_CAREER_POSITION = 'getCareerPosition';

/* Define Reset State Action Name */
const A_INITIAL_GET_CAREERS_STATE = 'initialGetCareersState';
const A_INITIAL_GET_CAREER_POSITION_STATE = 'initialGetCareerPositionState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_CAREERS = 'setGetCareers';
const M_GET_CAREER_POSITION = 'setGetCareerPosition';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_CAREERS_STATE = 'setInitialGetCareersState';
const M_INITIAL_GET_CAREER_POSITION_STATE = 'setInitialGetCareerPositionState';

/* Define Export Name */
export const CAREER_GET_CAREERS = MODULE_NAME + A_GET_CAREERS;
export const CAREER_GET_CAREER_POSITION = MODULE_NAME + A_GET_CAREER_POSITION;

/* Define Reset State Name */
export const CAREER_INITIAL_GET_CAREERS_STATE =
  MODULE_NAME + A_INITIAL_GET_CAREERS_STATE;
export const CAREER_INITIAL_GET_CAREER_POSITION_STATE =
  MODULE_NAME + A_INITIAL_GET_CAREER_POSITION_STATE;

const state = {
  careers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  position: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_CAREERS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CAREER.getCareers()
      .then((response) => {
        result = response;
        commit(M_GET_CAREERS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_CAREERS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CAREER_POSITION]({ dispatch, commit }, { id, positionId }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CAREER.getCareerPosition(id, positionId)
      .then((response) => {
        result = response;
        commit(M_GET_CAREER_POSITION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_CAREER_POSITION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_CAREERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_CAREERS_STATE);
  },
  [A_INITIAL_GET_CAREER_POSITION_STATE]({ commit }) {
    commit(M_INITIAL_GET_CAREER_POSITION_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_CAREERS](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.careers = {
        code: result.code,
        complete: true,
        data: d.map((x) => {
          return {
            id: x.id,
            title: x.title,
            active: false,
            position: x.position.map((y) => {
              return {
                id: y.id,
                title: y.title
              };
            })
          };
        }),
        message: null
      };
    } else {
      state.careers = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_CAREER_POSITION](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.position = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          title: d.title,
          content: d.content
        },
        message: null
      };
    } else {
      state.position = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_GET_CAREERS_STATE](state) {
    state.careers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_CAREER_POSITION_STATE](state) {
    state.position = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
