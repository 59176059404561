import { apiService } from '@/core/services';

export const COMPANY_WEB = {
  submitEmailSubscription,
  submitContactApplication
};

const URL = {
  WEB: 'web',
  EMAIL_SUBSCRIPTION: 'email-subscription',
  CONTACT_APPLICATION: 'contact-application'
};

function submitEmailSubscription(data) {
  let requestBody = {
    email: data.email
  };
  return apiService().post(
    `/${URL.WEB}/${URL.EMAIL_SUBSCRIPTION}`,
    requestBody
  );
}

function submitContactApplication(data) {
  let requestBody = {
    email: data.email,
    description: data.description
  };
  return apiService().post(
    `/${URL.WEB}/${URL.CONTACT_APPLICATION}`,
    requestBody
  );
}
