<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
    <AppDialogInfo></AppDialogInfo>
    <AppLoading></AppLoading>
  </v-app>
</template>

<script>
import Layout from '@/views/layout/Layout';
import BlankLayout from '@/views/layout/BlankLayout';
import AppDialogInfo from '@/views/components/base/AppDialogInfo';
import AppLoading from '@/views/components/base/AppLoading';
import { SHARED } from '@/core/constants';
import { i18nHelper } from '@/core/utils';

export default {
  name: 'App',
  metaInfo: {
    title: i18nHelper.getMessage('label.brandName'),
    titleTemplate: `%s | ${i18nHelper.getMessage('meta.description.default')}`
  },
  components: {
    Layout,
    AppDialogInfo,
    AppLoading,
    BlankLayout
  },
  computed: {
    layout() {
      return this.$route.meta.layout || SHARED.DEFAULT_LAYOUT;
    }
  },
  data: () => ({
    //
  })
};
</script>
<style>
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>
