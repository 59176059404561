import { i18nHelper } from '@/core/utils';

export const formValidationHelper = {
  requiredRules,
  usernameRules,
  passwordRules,
  confirmPasswordRules,
  emailRules,
  mobileNumberRules,
  twoDecimalRules,
  numericRules
};

const REGEX = Object.freeze({
  alphanumeric: /^[a-zA-Z0-9]+$/,
  username: /^[a-zA-Z0-9-. ]+$/,
  usernameNotAllowSpace: /^[a-zA-Z0-9-.]+$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  notAllowMultipleSpace: /^\S+(?: \S+)*$/,
  decimalTwo: /^[0-9]\d*(\.\d{1,2})?$/,
  numeric: /^[0-9]+$/,
  mobileNumber: /[0-9]{8,14}$/,
  mobileNumberMalaysia: /^[1][0-9][0-9]{7,8}$/,
  /* eslint-disable-next-line */ password:
    /^(?=.*[a-z])(?=.*[0-9])[A-Za-z\d\[\]\\\/\-~`!?@#$%^&*()_+={}|:;"'<>,.]+$/
});

/* Start Rules */
function requiredRules(fieldName, min = 0, max = 0) {
  return [
    requiredField(fieldName),
    notAllowMultipleSpace(),
    minMaxLength(min, max)
  ];
}

function usernameRules() {
  return [
    requiredField(),
    usernameFormat(),
    minMaxLength(6, 30),
    usernameNotAllowSpace()
  ];
}

function passwordRules() {
  return [requiredField() /*, passwordFormat()*/, minLength(6)];
}

function confirmPasswordRules(targetValue, value) {
  return [
    requiredField(),
    /* passwordFormat(),*/
    comparePasswordValue(targetValue, value),
    minLength(6, 30)
  ];
}

function emailRules() {
  return [
    requiredField(),
    emailFormat(),
    notAllowMultipleSpace(),
    minMaxLength(1, 50)
  ];
}

function mobileNumberRules(countryCode) {
  return [requiredField(), mobileNumberFormat(countryCode)];
}

function twoDecimalRules() {
  return [requiredField(), notAllowMultipleSpace(), twoDecimalFormat()];
}

function numericRules() {
  return [requiredField(), notAllowMultipleSpace(), numericFormat()];
}
/* end Rules */

/* Start Shared Validation */
function requiredField(fieldName) {
  return (v) =>
    !!v ||
    (fieldName
      ? i18nHelper.getMessage('fieldErrorMessage.requiredFieldDynamic', [
          fieldName
        ])
      : i18nHelper.getMessage('fieldErrorMessage.requiredField'));
}

function minMaxLength(min, max) {
  if (min == 0 && max == 0) {
    return true;
  } else {
    return (v) =>
      (v && v.length >= min && v.length <= max) ||
      i18nHelper.getMessage('fieldErrorMessage.exceedMinMaxLength', [min, max]);
  }
}
function minLength(min) {
  if (min == 0) {
    return true;
  } else {
    return (v) =>
      (v && v.length >= min) ||
      i18nHelper.getMessage('fieldErrorMessage.requiredMinLengthPassword', [
        min
      ]);
  }
}

function comparePasswordValue(targetValue, value) {
  return () =>
    targetValue === value ||
    i18nHelper.getMessage('fieldErrorMessage.confirmPasswordNotMatch');
}
/* End Shared Validation */

/* Start Format Validation */
function usernameNotAllowSpace() {
  return (v) =>
    (v && REGEX.usernameNotAllowSpace.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidUsernameNotAllowSpace');
}

function notAllowMultipleSpace() {
  return (v) =>
    (v && REGEX.notAllowMultipleSpace.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.multipleSpace');
}

function usernameFormat() {
  return (v) =>
    (v && REGEX.username.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidUsernameFormat');
}

function emailFormat() {
  return (v) =>
    (v && REGEX.email.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidEmailFormat');
}

function twoDecimalFormat() {
  return (v) =>
    (v && REGEX.decimalTwo.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidDecimalFormat');
}

function numericFormat() {
  return (v) =>
    (v && REGEX.numeric.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidNumericFormat');
}

function mobileNumberFormat(countryCode) {
  let mobileNumberRegex = REGEX.mobileNumber;
  switch (countryCode) {
    case 'my':
      mobileNumberRegex = REGEX.mobileNumberMalaysia;
      break;
    default:
      break;
  }
  return (v) =>
    (v && mobileNumberRegex.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidMobileNumberFormat');
}

// function passwordFormat() {
//   return (v) =>
//     (v && REGEX.password.test(v)) ||
//     i18nHelper.getMessage('fieldErrorMessage.invalidPasswordFormat');
// }

/* End Format Validation*/
